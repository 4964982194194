const titles = {
    patientProfileList: "Patiënt Profiel Lijst",
    hello: "Hallo",
    patientName: "Patiënt Naam",
    patientId: "Patiënt Id",
    creationDate: "Aanmaakdatum",
    lastUpdate: "Laatste Update",
    status: "Status",
    action: "Actie",
    profileId: "Profiel Id",
    sizeOfProfilePopulation: "Grootte van Profielpopulatie",
    numberOfInclusionRules: "Aantal Inclusieregels",
    numberOfRiskGroups: "Aantal Risicogroepen",
    modelProfileList: "Model Profiel Lijst",
    view: "Bekijk",
    export: "Exporteer",
    import: "Importeer",
    completed: "Voltooid",
    delete: "Verwijder",
    generatePrediction: "Genereer Voorspelling",
    draft: "Concept",
    addNewPatient: "Voeg Nieuwe Patiënt Toe",
    patientProfile: "Patiënt Profiel",
    predictionGenerators: "Voorspellingsgeneratoren",
    readMission: "Heropname",
    basedOnModels: "Gebaseerd Op Modellen",
    frailtyPredictions: "Kwetsbaarheid Voorspellingen",
    predictedIndividualTreatmentEffect: "Voorspeld Individueel Behandelingseffect",
    deleteProfile: "Verwijder Profiel",
    editProfile: "Bewerk Profiel",
    dashboard: "Dashboard",
    predictionModel: "WP4 Heropnamemodel",
    update: "Bijwerken",
    editPatientProfile: "Bewerk Patiënt Profiel",
    generalInformation: "Algemene Informatie",
    fullName: "Volledige Naam",
    countryOfOrigin: "Land van Oorsprong",
    chf: "CHF",
    copd: "COPD",
    uti: "UTI",
    emailAddress: "E-mailadres",
    password: "Wachtwoord",
    forgotPassword: "Wachtwoord Vergeten",
    login: "Inloggen",
    signInToYourAccount: "Log in op je account",
    welcomeBack: "Welkom Terug",
    hospitalization_history: "ZIEKENHUISGESCHIEDENIS",
    usti: "UTI",
    deteriorated_more_services: "VERSLECHTERING MEERDIENSTEN",
    stasis_ulcer: "STASE ULCER",
    skin_tears_or_cut: "HUIDSCHEUREN OF SNEDEN",
    lessons: "LESSEN",
    fallany: "VALANY",
    other_fracture: "ANDERE FRACTUUR",
    cancer: "KANKER",
    infuse: "INFUSEREN",
    dialysis: "DIALYSE",
    er_visits_in_the_last_90_days_or_since_last_assessment: "ER BEZOEKEN IN DE LAATSTE 90 DAGEN OF SINDS LAATSTE BEVINDING",
    renal_failure: "NIERFALEN",
    gender: "GESLACHT",
    irregular_pulse: "IRREGULIER POLS",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "ONGEPLAND GEWICHTVERLIES VAN 5(%) OF MEER IN DE LAATSTE 30 DAGEN",
    ulcer_wound_care_surgical_wound_care: "ULCER WONDVERZORGING CHIRURGISCHE WONDVERZORGING",
    pressure_ulce: "DRUKULCER",
    hip_fracture: "HEUPFRACTUUR",
    client_feels_having_a_poor_health: "CLÏENT VOELT ZICH ONGEZOND",
    experiencing_flare_up_of_recurrent_condition_or_problem: "ERVARING VAN OPSPEL VAN RECIDIVERENDE TOESTAND OF PROBLEEM",
    adl_decline: "ADL AFNAME",
    baseline_frailty_score: "BASIS KWETSBAARHEIDSSCORE",
    baseline_frailty_subset1_cognition_collapsed: "BASIS KWETSBAARHEID SUBSET1 COGNITIE SAMENGEVALLEN",
    baseline_frailty_subset1_clinical_collapsed: "BASIS KWETSBAARHEID SUBSET1 CLINISCH SAMENGEVALLEN",
    short_term_memory: "KORTDURIG GEHEUGEN",
    worsening_of_decision_making: "VERSLECHTERING VAN BESLUITVORMING",
    alzheimers: "ALZHEIMERS",
    dementia_other_than_heart_disease: "DEMENTIE ANDERS DAN HARTZIEKTE",
    parkinsonism: "PARKINSONISME",
    eating: "ETEN",
    tranferring_oneself_from_surfaces: "ZICHZELF VAN OPVLAKKEN OVERPLAATSEN",
    bladder_devices_indwelling_urinary_catheter: "BLAASAPPARATEN INWONENDE URINEKATHETER",
    pneumonia: "LONGONTSTEKING",
    procedural_memory: "PROCEDURELE HERINNERING",
    fever: "KOORTS",
    diagnosis: "Diagnose",
    age: "Leeftijd",
    unplanned_hospital_or_er_visit: "ONGEPLAND ZIEKENHUIS OF ER BEZOEK",
    number_of_comorbidities: "AANTAL COMORBIDITEITEN",
    unsteady_gait: "ONSTEADIGE LOOP",
    diuretics: "DIURETICA",
    pain_scale: "Pijnschaal",
    client_feels_he_she_has_poor_health: "CLÏENT VOELT DAT HIJ ZIJN GEZONDHEID SLECHT IS",
    chess: "SCHAAK",
    adl_status_has_become_worse: "ADL STATUS IS SLECHTER GEWORDEN",
    security_service: "BEVEILIGINGSERVICE",
    visiting_nurses: "BEZOEKENDE VERPLEEGSTERS",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "Cliënt ging het huis uit (1 dag per week of geen dagen)",
    impaired_vision: "VERSTOORD ZICHT",
    caregivers_belief_client_capable: "OPVANGERS GELIJKEN CLIENT CAPABEL",
    drs: "DOKTERS",
    psychotropic_medication: "PSYCHOTROPE MEDICATIE",
    bmi: "BMI",
    adl_long: "ADL LANG",
    cancel: "Annuleren",
    saveAndClose: "Opslaan en Sluiten",
    enterToSearch: "Invoeren om te Zoeken",
    viewModelList: "Bekijk Model Lijst",
    chancesHospitalizationInNext12Months: "Kansen op ziekenhuisopname in de komende 12 maanden",
    inclusionRules: "Inclusieregels",
    variableIndex: "Variabele Index",
    variableName: "Variabele Naam",
    variableValue: "Variabele Waarde",
    valueSymetricOddScore: "Waarde (Symmetrisch) Oneven Score",
    oddSymetricScoreSet: "Oneven Symmetrische Score Set",
    riskGroups: "Risicogroepen",
    threshold: "Drempel",
    dependent_value: "Afhankelijke Waarde",
    performance: "Prestatie",
    sample_size: "Steekproefgrootte",
    previous_hospitalization_events_in_last_year: "EERDERE ZIEKENHUISOPNAME EVENEMENTEN IN HET LAATSTE JAAR",
    welcome: "Welkom!",
    back: "Terug",
    frailtyPrediction: "Kwetsbaarheid Voorspelling",
    viewModelProfile: "Bekijk Modelprofiel",
    users: "Gebruikers",
    usersList: "Gebruikerslijst",
    addNewUser: "Voeg Nieuwe Gebruiker Toe",
    name: "Naam",
    email: "E-mail",
    isVerified: "Is Geverifieerd",
    yes: "Ja",
    no: "Nee",
    edit: "Bewerken",
    resendActivationLink: "Stuur Activatielink Opnieuw",
    add: "Toevoegen",
    user: "Gebruiker",
    passwordConfirmation: "Bevestiging Wachtwoord",
    newPatientProfile: "Nieuw Patiëntprofiel",
    userForm: "Gebruikersformulier",
    modelProfile: "Modelprofiel",
    newModelProfile: "Nieuw Modelprofiel",
    locomodation_in_home: "Locomotie Thuis",
    meal_preparation: "Maaltijdvoorbereiding",
    stairs: "Trappen",
    short_term_memory_ok: "Korte Termijn Geheugen",
    alzheimers_disease: "Ziekte van Alzheimer",
    dementia_other_than_alzheimers_disease: "Dementie anders dan de ziekte van Alzheimer",
    paraplegia: "Paraplegie",
    mental_health_delusions: "Psychische Delusies",
    mental_health_hallucinations: "Psychische Hallucinaties",
    bowel_continence: "Darmcontinentie",
    meal: "Maaltijd",
    housework: "Huishoudelijk Werk",
    shopping: "Winkelen",
    iadl_alzheimers: "IADL Alzheimer",
    other_dementia: "Andere Dementie",
    bath: "Bad",
    difficulty_meal_preparation: "Moeite met Maaltijdvoorbereiding",
    revised_iadl_hierarchy: "Herziene IADL Hiërarchie",
    short_memory_ok: "Korte Geheugen Ok",
    iadl_decline: "IADL Afname",
    iadlModel: "WP4 IADL Model",
    iadlProfileList: "IADL Profiel Lijst",
    iadlProfile: "IADL Profiel",
    viewIadlModelList: "Bekijk IADL Model Lijst",
    viewIadlModel: "Bekijk IADL Model",
    individualTreatment: "Individuele Behandeling",
    seeAll: "Alles Zien",
    iadlDecline: "HC: IADL Afname",
    hospitalization: "HC: Ziekenhuisopname",
    qualityOfLife: "NH: Kwaliteit van Leven",
    frailty: "NH: Kwetsbaarheid",
    viewQualityOfLifeList: "Bekijk Kwaliteit van Leven Lijst",
    viewFrailtyList: "Bekijk Kwetsbaarheid Lijst",
    viewFrailty: "Bekijk Kwetsbaarheid",
    hospitalizationTooltip: "Het individuele behandelingseffect (ITE) dat een absolute maat voor risico aangeeft die de verandering (ten opzichte van de basislijn) in de waarschijnlijkheid van ziekenhuisopname (volgende 12 maanden) voor dat specifieke individu als gevolg van behandeling aangeeft.",
    adlTooltip: "De verandering (ten opzichte van de basislijn) in de voorspelde uitkomstwaarde voor dat specifieke individu als gevolg van behandeling. Bijvoorbeeld, een ITE van +1.2 score is de verandering van de voorspelde score door ITE (op een schaal van 0 tot 6) voor dat specifieke individu. Dit nummer kan in percentage worden uitgedrukt dat de verandering in CPS-score door ITE een toename van 20% (1.2/6*100) is voor dat specifieke individu.",
    cpsTooltip: "De verandering (ten opzichte van de basislijn) in de voorspelde uitkomstwaarde voor dat specifieke individu als gevolg van behandeling. Bijvoorbeeld, een ITE van +1.2 score is de verandering van de voorspelde score door ITE (op een schaal van 0 tot 6) voor dat specifieke individu. Dit nummer kan in percentage worden uitgedrukt dat de verandering in CPS-score door ITE een toename van 20% (1.2/6*100) is voor dat specifieke individu.",
    chessTooltip: "De verandering (ten opzichte van de basislijn) in de voorspelde uitkomstwaarde voor dat specifieke individu als gevolg van behandeling. Bijvoorbeeld, een ITE van +1.2 score is de verandering van de voorspelde score door ITE (op een schaal van 0 tot 6) voor dat specifieke individu. Dit nummer kan in percentage worden uitgedrukt dat de verandering in CPS-score door ITE een toename van 20% (1.2/6*100) is voor dat specifieke individu.",
    fallsTooltip: "Het individuele behandelingseffect (ITE) dat een absolute maat voor risico aangeeft die de verandering (ten opzichte van de basislijn) in de waarschijnlijkheid van een val (volgende 3-6 maanden) voor dat specifieke individu als gevolg van behandeling aangeeft.",
    role: "Rol",
    isLogin: "Is Ingelogd?",
    prePilotDashboard: "Dashboard",
    isVisitFormLink: "Is Bezoekformulier Link?",
    qualityOfLifeN: "Kwaliteit van Leven",
    frailtyN: "Kwetsbaarheid",
    category: "Categorie",
    type: "Type",
    baseOnModel: "Gebaseerd op Model",
    item: "Item",
    addSuccessfully: "Succesvol Toegevoegd",
    updateSuccessfully: "Succesvol Bijgewerkt",
    errorMessage: "Ops, er is iets mis gegaan, probeer het opnieuw",
    questionSubmittedSuccess: "Vraag Succesvol Indienen",
    deletedSuccess: "Succesvol Verwijderd",
    resetPasswordSuccessMsg: "Link om wachtwoord opnieuw in te stellen is succesvol naar uw e-mail verzonden.",
    successLogin: "U bent succesvol ingelogd",
    otpSuccessMsg: "OTP succesvol naar uw mail verzonden",
    alreadyRegistered: "Al Geregistreerd!",
    otpVerifiedMsg: "OTP Succesvol Geverifieerd!",
    otpSentSuccessMsg: "OTP Succesvol Verzonden!",
    registerSuccessMsg: "Succesvol Geregistreerd!",
    tokenInvalidMsg: "Token ongeldig of verlopen, probeer het opnieuw",
    passwordResetSuccessMsg: "Wachtwoord Succesvol Gereset!",
    yourRequestSubmitSuccessMsg: "Uw verzoek is succesvol ingediend",
    patientImportSuccessMsg: "Patiënt Succesvol Geïmporteerd",
    deletedSuccessMsg: "Succesvol Verwijderd",
    accountAlreadyExistsMsg: "Uw account is succesvol geregistreerd bij ons, log nu in!",
    fileUploadSuccessMsg: "Bestand Succesvol Geüpload",
    fileDeleteMsg: "Bestand Succesvol Verwijderd",
    click: "Klik",
    here: "Hier",
    postQuestionnaireSentence: "op deze link en vul het formulier in voor verdere verwerking van",
    postQuestionnaire: "post-vragenlijst",
    hospitalizationTooltip2: "Waarschijnlijkheid van ziekenhuisopname in een medische faciliteit in de volgende 12 maanden.",
    adlTooltip2: "Waarschijnlijkheid van significante verslechtering in IADL in de volgende 12 maanden.",
    patient: "Patiënt",
    predictionTopic: "Voorspellingen in 12 maanden",
    riskOfDeclineOfEvent: "Risico op achteruitgang/gebeurtenis",
    whatIfYouDecideTo: "Wat als u besluit om:",
    stopAntipsychotics: "Antipsychotica te stoppen",
    startAnticholinergics: "Anticholinergica te starten",
    startPhysiotherapy: "Fysiotherapie te starten",
    predictedChange: "Voorspelde verandering",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "VALPARTIJEN",
    configuration: "Configuratie",
    code: "Code",
    label: "Label",
    index: "Index",
    wp4: "WP4",
    configurations: "Configuraties",
    configurationWp4Form: "Configuratie WP4 Formulier",
    configurationWp4SectionForm: "Configuraties WP4 Sectie Formulier",
    wp4Section: "WP4 Sectie",
    title: "Titel",
    section: "Sectie",
    isEmailVerified: "Is E-mail geverifieerd?",
    areYourSureWantToLogout: "Weet u zeker dat u wilt uitloggen?",
    postQuestion: "voor beslissingsvragenlijst.",
    toProvideFeedback: "om feedback te geven.",
    logout: "Uitloggen",
    clone: "Kloon",
    cloneSuccessMsg: "Patiëntgegevens succesvol gekloond",
    codeSystem: "Codesysteem",
    importExternalPatient: "Externe Patiënt Importeren",
    addPatient: "Patiënt Toevoegen",
    patientList: "Patiëntenlijst",
    patientForm: "Patiëntformulier",
    save: "Opslaan",
    link1: "Link1",
    link2: "Link2",
    patientFeedbackLink: "Patiënt Feedback Link",
    forDecisionQuestionnaire: "voor Beslissingsvragenlijst",
    link: "Link",
    showRaiData: "Toon RAI-gegevens",
    close: "Sluiten",
    startPhysiotherapyContent: "Tijdsperiode: Binnen 6 maanden; Definitie van fysiotherapie: minstens 4 sessies per week, van elk minimaal 15 minuten. Uitzondering hierop is CPS, daarvoor werd 5 dagen gebruikt.",
    startPhysiotherapyContent1: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect onder behandeling en het voorspelde effect als de patiënt geen behandeling ontvangt. Een voorspeld effect lager dan 0 geeft aan dat er minder achteruitgang is, geen achteruitgang, of een grotere verbetering met therapie vergeleken met geen therapie. Een voorspeld effect hoger dan 0 geeft aan dat er meer achteruitgang is met therapie vergeleken met geen therapie.",
    startPhysiotherapyContent2: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect onder behandeling en het voorspelde effect als de patiënt geen behandeling ontvangt. Een voorspeld effect hoger dan 0 geeft aan dat er minder achteruitgang is, geen achteruitgang, of een grotere verbetering met therapie vergeleken met geen therapie. Een voorspeld effect lager dan 0 geeft aan dat er meer achteruitgang is met therapie vergeleken met geen therapie.",
    startPhysiotherapyContentAdl: "Het individueel behandelingsresultaat (ITE) is het verschil tussen het voorspelde effect onder behandeling en het voorspelde effect als de patiënt geen behandeling krijgt. Een voorspeld effect lager dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering met therapie vergeleken met geen therapie. Een voorspeld effect hoger dan 0 duidt op een grotere achteruitgang met therapie vergeleken met geen therapie; Tijdperiode: tot 6 maanden; Resultaat: Activiteiten van het Dagelijks Leven Lange Vorm, van 0 tot 28 punten.",
    stopAntipsychoticsContent1: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het stoppen van antipsychotische medicatie en het voorspelde effect van het voortzetten van de medicatie. Een voorspeld effect van minder dan 0 geeft aan dat er minder achteruitgang is, geen achteruitgang, of een grotere verbetering van CHESS bij het stoppen van antipsychotische medicatie vergeleken met het voortzetten van de medicatie. Een voorspeld effect groter dan 0 geeft aan dat er meer achteruitgang van CHESS is bij het stoppen van antipsychotische medicatie vergeleken met het voortzetten van de medicatie. Tijdskader: tot 12 maanden.",
    stopAntipsychoticsContentAdl: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met antipsychotica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent minder achteruitgang, geen achteruitgang of vooruitgang in CPS bij het stoppen met antipsychotica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een grotere achteruitgang in CPS bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan.",
    stopAntipsychoticsContentCps: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met antipsychotica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent minder achteruitgang, geen achteruitgang of vooruitgang in CHESS bij het stoppen met antipsychotica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een grotere achteruitgang in CHESS bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan.",
    stopAntipsychoticsContentChess: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met antipsychotica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent minder achteruitgang, geen achteruitgang of vooruitgang in ADL bij het stoppen met antipsychotica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een grotere achteruitgang in ADL bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan.",
    stopAntipsychoticsContentHospitalization: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met antipsychotica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent een lagere kans op ziekenhuisopname bij het stoppen met antipsychotica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een hogere kans op ziekenhuisopname bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan.",
    startAnticholinergicsAdl: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met anticholinergica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent minder achteruitgang, geen achteruitgang of vooruitgang in ADL bij het stoppen met anticholinergica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een grotere achteruitgang in ADL bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan. Tijdsperiode: Binnen 12 maanden",
    startAnticholinergicsCps: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met anticholinergica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent minder achteruitgang, geen achteruitgang of vooruitgang in CPS bij het stoppen met anticholinergica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een grotere achteruitgang in CPS bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan. Tijdsperiode: Binnen 12 maanden",
    startAnticholinergicsChess: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met anticholinergica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent een lagere kans op vallen bij het stoppen met anticholinergica t.o.v. het voorzetten daarvan. Een IBE groter dan 0 betekent een grotere akans op vallen bij het stoppen van antipsychotica t.o.v. het voortzetten daarvan. Tijdsperiode: Terugblik tot 90 dagen op follow-up moment op 12 maanden",
    startAnticholinergicsHospitalization: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect van minder dan 0 geeft aan dat er een lagere kans op ziekenhuisopname is na het starten van de medicatie vergeleken met geen medicatie. Een voorspeld effect hoger dan 0 geeft aan dat er een hogere kans op ziekenhuisopname is met medicatie vergeleken met geen medicatie. Tijdskader: tot 12 maanden.",
    startAnticholinergicsFalls: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 geeft aan dat er een lagere kans op vallen is na het starten van de medicatie vergeleken met geen medicatie. Een voorspeld effect hoger dan 0 geeft aan dat er een hogere kans op vallen is met medicatie vergeleken met geen medicatie. Tijdskader: een terugkijkperiode van 90 dagen na 12 maanden.",
    questionnairePopupHeading: "Algemene disclaimer voor alle voorspellingen",
    questionnairePopupHeadingList1: "Gebruik alstublieft voorzichtigheid bij uw beslissing en weeg uw klinische expertise in uw uiteindelijke beslissing, aangezien de nauwkeurigheid van modellen varieert tussen zorgontvangers.",
    questionnairePopupHeadingList2: "Deze voorspellingen zijn ontwikkeld en gevalideerd door het I-CARE4OLD-consortium.",
    questionnairePopupHeadingList3: "Voorspellingen zijn geïnformeerd door geavanceerde analyses, waaronder machine learning. We hebben de beste wetenschap toegepast op hoogwaardige routinezorggegevens uit meerdere landen.",
    questionnairePopupHeadingList4: "Validaties zijn uitgevoerd op zeer grote monsters van hoogwaardige longitudinale gegevens van zorgontvangers in de thuiszorg of verpleeghuizen uit België, Canada, Finland, Hongkong, Italië, Nieuw-Zeeland, Nederland en de VS.",
    questionnairePopupHeadingList5: "Meer info: www.icare4old.eu / Begeleidingstekst",
    description: "Beschrijving",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Wp6 Voorspelling",
    configurationWp5Form: "Configuratie Wp5 Formulier",
    configurationWp6Form: "Configuratie Wp6 Formulier",
    boxType: "Doeltype",
    addNewSection: "Voeg Nieuwe Sectie Toe",
    translation: "Vertaling",
    upload: "Uploaden",
    noRecordFound: "Geen Record Gevonden",
    attachments: "Bijlagen",
    questionnairePopupHeadingList6: "op deze link en vul het formulier in voor verdere verwerking van",
    preQuestionnaire: "Voorvragenlijst",
    homeCare: "Thuiszorg",
    nursingHome: "Verpleeghuis",
    afterGeneratePredictionPopup: "Heeft u kennisgemaakt met het patiëntenprofiel?",
    thankYou: "Dank u!",
    predictionIn12Months: "Voorspellingen voor over 12 maanden",
    functionWorsening: "Functionele achteruitgang (ADL + iADL)",
    cognitionWorsening: "Cognitieve achteruitgang",
    healthInstability: "Kwetsbare gezondheid (CHESS)",
    otherFalls: "Overig (Vallen)",
    qualityOfLifeWorsening: "Achteruitgang in kwaliteit van leven",
    riskOfDecline: "Risico op gebeurtenis",
    whatIfYouDecide: "Wat als u besluit om:",
    probabilityOfHospitalization: "Kans op opname in een ziekenhuis of instelling in de komende 12 maanden",
    probabilityOfWorseningIADL: "Kan op een significante verslechtering in iADL in de komende 12 maanden",
    otherFallsInfoBox: "Het individuele behandelingseffect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergische medicatie en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 geeft aan dat er een lagere kans op vallen is na het starten van de medicatie vergeleken met geen medicatie. Een voorspeld effect hoger dan 0 geeft aan dat er een hogere kans op vallen is met medicatie vergeleken met geen medicatie. Tijdskader: een terugkijkperiode van 90 dagen na 12 maanden.",
    lastAllInfoBox: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect van stoppen met anticholinergica en het voorspelde effect van de voortzetting daarvan. Een IBE kleiner dan 0 betekent minder achteruitgang, geen achteruitgang of vooruitgang bij het starten met de therapie t.o.v. geen therapie. Een IBE groter dan 0 betekent een grotere achteruitgang bij het starten met de therapie t.o.v. geen therapie. Tijdsperiode: Binnen 6 maanden",
    hospitalizations: "Ziekenhuisopname",
    forgotYourPassword: "Wachtwoord vergeten?",
    forgotPasswordInstructions: "Voer alstublieft uw e-mailadres in en we zullen u een e-mail met instructies sturen.",
    forgotPasswordHeading: "Let op: E-mail is geldig voor 24 uur.",
    submit: "Indienen",
    resetYourPassword: "Uw wachtwoord opnieuw instellen?",
    pleaseEnterNewPassword: "Voer alstublieft een nieuw wachtwoord in.",
    resetPasswordHeading: "Let op: E-mail is geldig voor 24 uur.",
    setupYourPasswordForNewAccount: "Stel uw wachtwoord in voor een nieuw account.",
    resetPasswordNewHeading: "Voer alstublieft uw wachtwoord in en uw account wordt geregistreerd bij ons, zodat u kunt inloggen.",
    resetPasswordNewHeading1: "Let op: E-mail is geldig voor 24 uur.",
    patientProfilePredictionVerification: "Verificatie van de patiëntprofielvoorspelling",
    position:"Positie",
    after:"Na",
    before:"Voor",
    predictedChangeFromBasePredictionInCaseOf:"Voorspelde verandering ten opzichte van de basisvoorspelling in het geval van"
};


export default {
    locale: "nl-BE",
    messages: {
        title: titles
    },
};
