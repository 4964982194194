const titles = {
    patientProfileList: "Lijst van Patiëntenprofielen",
    hello: "Hallo",
    patientName: "Naam van de Patiënt",
    patientId: "Patiënt ID",
    creationDate: "Aanmaakdatum",
    lastUpdate: "Laatste Update",
    status: "Status",
    action: "Actie",
    profileId: "Profiel ID",
    sizeOfProfilePopulation: "Grootte van de Profielpopulatie",
    numberOfInclusionRules: "Aantal Inclusiecriteria",
    numberOfRiskGroups: "Aantal Risicogroepen",
    modelProfileList: "Model Profiel Lijst",
    view: "Bekijk",
    export: "Exporteer",
    import: "Importeer",
    completed: "Voltooid",
    delete: "Verwijder",
    generatePrediction: "Voorspelling Genereren",
    draft: "Concept",
    addNewPatient: "Voeg Nieuwe Patiënt Toe",
    patientProfile: "Patiëntenprofiel",
    predictionGenerators: "Voorspellingsgeneratoren",
    readMission: "Heropname",
    basedOnModels: "Gebaseerd op Modellen",
    frailtyPredictions: "Kwetsbaarheid Voorspellingen",
    predictedIndividualTreatmentEffect: "Voorspeld Individueel Behandelingseffect",
    deleteProfile: "Verwijder Profiel",
    editProfile: "Bewerk Profiel",
    dashboard: "Dashboard",
    predictionModel: "WP4 Heropname model",
    update: "Bijwerken",
    editPatientProfile: "Bewerk Patiëntenprofiel",
    generalInformation: "Algemene Informatie",
    fullName: "Volledige Naam",
    countryOfOrigin: "Land van Oorsprong",
    chf: "CHF",
    copd: "COPD",
    uti: "UTI",
    emailAddress: "E-mailadres",
    password: "Wachtwoord",
    forgotPassword: "Wachtwoord Vergeten",
    login: "Inloggen",
    signInToYourAccount: "Log in op uw account",
    welcomeBack: "Welkom Terug",
    hospitalization_history: "ZIEKENHUISOPNAMEGESCHIEDENIS",
    usti: "UTI",
    deteriorated_more_services: "VERSLECHTERDE MEERDERE DIENSTEN",
    stasis_ulcer: "STASISZWEER",
    skin_tears_or_cut: "HUIDSPLITSINGEN OF SNEDEN",
    lessons: "LESSEN",
    fallany: "VALGEVAL",
    other_fracture: "ANDERE FRACTUUR",
    cancer: "KANKER",
    infuse: "INFUSEREN",
    dialysis: "DIALYSE",
    er_visits_in_the_last_90_days_or_since_last_assessment: "SEH BEZOEKEN IN DE LAATSTE 90 DAGEN OF SINDS LAATSTE BEVINDING",
    renal_failure: "NIERFAILLURE",
    gender: "GESLACHT",
    irregular_pulse: "ONREGELMATIGE PULS",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "ONBEPLAND GEWICHTVERLIES VAN 5(%) OF MEER IN DE LAATSTE 30 DAGEN",
    ulcer_wound_care_surgical_wound_care: "ZWEER WONDZORG CHIRURGISCHE WONDZORG",
    pressure_ulce: "DRUKZWEER",
    hip_fracture: "HEUPFRACTUUR",
    client_feels_having_a_poor_health: "CLIËNT VOELT ZICH ZIEK",
    experiencing_flare_up_of_recurrent_condition_or_problem: "ERVAART OPVLAKKEN VAN TERUGKERENDE AANDOENING OF PROBLEEM",
    adl_decline: "ADL AFDALING",
    baseline_frailty_score: "BASIS KWETSBAARHEIDSSCORE",
    baseline_frailty_subset1_cognition_collapsed: "BASIS KWETSBAARHEID SUBSET1 COGNITIE SAMENGETROKKEN",
    baseline_frailty_subset1_clinical_collapsed: "BASIS KWETSBAARHEID SUBSET1 CLINISCH SAMENGETROKKEN",
    short_term_memory: "KORTETERMIJNGEHEUGEN",
    worsening_of_decision_making: "VERSLECHTERING VAN BESLUITVORMING",
    alzheimers: "ALZHEIMERS",
    dementia_other_than_heart_disease: "DEMENTIE ANDERS DAN HARTZIEKTE",
    parkinsonism: "PARKINSONISME",
    eating: "ETEN",
    tranferring_oneself_from_surfaces: "ZICH VAN OPPERVLAKKEN OVERBRENGEN",
    bladder_devices_indwelling_urinary_catheter: "BLAASAPPARATEN INWONENDE URINECATHEETER",
    pneumonia: "LONGONTSTEKING",
    procedural_memory: "PROCEDUREEL GEHEUGEN",
    fever: "KOORTS",
    diagnosis: "Diagnose",
    age: "Leeftijd",
    unplanned_hospital_or_er_visit: "ONGEPLAND ZIEKENHUIS OF SEH BEZOEK",
    number_of_comorbidities: "AANTAL COMORBIDITEITEN",
    unsteady_gait: "ONSTABIELE LOOP",
    diuretics: "DIURETICA",
    pain_scale: "PAAIN SCHAAL",
    client_feels_he_she_has_poor_health: "CLIËNT VOELT ZICH ZIEK",
    chess: "CHESS",
    adl_status_has_become_worse: "ADL STATUS IS SLECHTER GEWORDEN",
    security_service: "BEVEILIGINGSERVICE",
    visiting_nurses: "BEZOEKENDE VERPLEEGKUNDIGEN",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "Cliënt ging uit huis (1 dag per week of geen dagen)",
    impaired_vision: "SLECHT ZICHT",
    caregivers_belief_client_capable: "GEZINSVERZORGERS GELIJKEN CLIËNT CAPABEL",
    drs: "DOKTERS",
    psychotropic_medication: "PSYCHOTROPE MEDICATIE",
    bmi: "BMI",
    adl_long: "ADL LANG",
    cancel: "Annuleer",
    saveAndClose: "Opslaan en Sluiten",
    enterToSearch: "Vul in om te zoeken",
    viewModelList: "Bekijk Model Lijst",
    chancesHospitalizationInNext12Months: "Kansen op ziekenhuisopname in de komende 12 maanden",
    inclusionRules: "Inclusiecriteria",
    variableIndex: "Variabele Index",
    variableName: "Variabele Naam",
    variableValue: "Variabele Waarde",
    valueSymetricOddScore: "Waarde (Symmetrisch) Odd Score",
    oddSymetricScoreSet: "Odd Symmetrische Score Set",
    riskGroups: "Risicogroepen",
    threshold: "Drempel",
    dependent_value: "Afhankelijke Waarde",
    performance: "Prestatie",
    sample_size: "Steekproefgrootte",
    previous_hospitalization_events_in_last_year: "VORIGE ZIEKENHUISOPNAMEN IN HET LAATSTE JAAR",
    welcome: "Welkom!",
    back: "Terug",
    frailtyPrediction: "Kwetsbaarheid Voorspelling",
    viewModelProfile: "Bekijk Model Profiel",
    users: "Gebruikers",
    usersList: "Gebruikerslijst",
    addNewUser: "Voeg Nieuwe Gebruiker Toe",
    name: "Naam",
    email: "E-mail",
    isVerified: "Is Geverifieerd",
    yes: "Ja",
    no: "Nee",
    edit: "Bewerk",
    resendActivationLink: "Verzend Activatielink Opnieuw",
    add: "Toevoegen",
    user: "Gebruiker",
    passwordConfirmation: "Wachtwoordbevestiging",
    newPatientProfile: "Nieuw Patiëntenprofiel",
    userForm: "Gebruikersformulier",
    modelProfile: "Modelprofiel",
    newModelProfile: "Nieuw Modelprofiel",
    locomodation_in_home: "Locomotie Thuis",
    meal_preparation: "Maaltijdvoorbereiding",
    stairs: "Trappen",
    short_term_memory_ok: "Korte Termijn Geheugen",
    alzheimers_disease: "Alzheimer's ziekte",
    dementia_other_than_alzheimers_disease: "Dementie anders dan Alzheimer’s ziekte",
    paraplegia: "Paraplegie",
    mental_health_delusions: "Psychische Delusies",
    mental_health_hallucinations: "Psychische Hallucinaties",
    bowel_continence: "Darmcontinentie",
    meal: "Maaltijd",
    housework: "Huishoudelijk Werk",
    shopping: "Winkelen",
    iadl_alzheimers: "IADL Alzheimer’s",
    other_dementia: "Andere Dementie",
    bath: "Bad",
    difficulty_meal_preparation: "Moeite met Maaltijdvoorbereiding",
    revised_iadl_hierarchy: "Herschikte IADL Hiërarchie",
    short_memory_ok: "Korte Geheugen Ok",
    iadl_decline: "IADL Afdaling",
    iadlModel: "WP4 IADL model",
    iadlProfileList: "IADL Profiel Lijst",
    iadlProfile: "IADL Profiel",
    viewIadlModelList: "Bekijk IADL Model Lijst",
    viewIadlModel: "Bekijk IADL Model",
    individualTreatment: "Individuele Behandeling",
    seeAll: "Bekijk Alles",
    iadlDecline: "HC: IADL Afdaling",
    hospitalization: "HC: Ziekenhuisopname",
    qualityOfLife: "NH: Kwaliteit van Leven",
    frailty: "NH: Kwetsbaarheid",
    viewQualityOfLifeList: "Bekijk Kwaliteit van Leven Lijst",
    viewFrailtyList: "Bekijk Kwetsbaarheid Lijst",
    viewFrailty: "Bekijk Kwetsbaarheid",
    hospitalizationTooltip: "Het individuele behandelingseffect (ITE) is een absolute maatstaf voor risico die de verandering (ten opzichte van de basislijn) in de waarschijnlijkheid van ziekenhuisopname (volgende 12 maanden) voor dat specifieke individu als gevolg van behandeling aangeeft.",
    adlTooltip: "De verandering (ten opzichte van de basislijn) in de voorspelde uitkomstwaarde voor dat specifieke individu als gevolg van behandeling. Bijvoorbeeld, een ITE van +1.2 score is de verandering van de voorspelde score door ITE (op een schaal van 0 tot 6) voor dat specifieke individu. Dit nummer kan in percentage worden uitgedrukt dat de verandering in CPS-score door ITE een stijging van 20% is (1.2/6*100) voor dat specifieke individu.",
    cpsTooltip: "De verandering (ten opzichte van de basislijn) in de voorspelde uitkomstwaarde voor dat specifieke individu als gevolg van behandeling. Bijvoorbeeld, een ITE van +1.2 score is de verandering van de voorspelde score door ITE (op een schaal van 0 tot 6) voor dat specifieke individu. Dit nummer kan in percentage worden uitgedrukt dat de verandering in CPS-score door ITE een stijging van 20% is (1.2/6*100) voor dat specifieke individu.",
    chessTooltip: "De verandering (ten opzichte van de basislijn) in de voorspelde uitkomstwaarde voor dat specifieke individu als gevolg van behandeling. Bijvoorbeeld, een ITE van +1.2 score is de verandering van de voorspelde score door ITE (op een schaal van 0 tot 6) voor dat specifieke individu. Dit nummer kan in percentage worden uitgedrukt dat de verandering in CPS-score door ITE een stijging van 20% is (1.2/6*100) voor dat specifieke individu.",
    fallsTooltip: "Het individuele behandelingseffect (ITE) is een absolute maatstaf voor risico die de verandering (ten opzichte van de basislijn) in de waarschijnlijkheid van een val (volgende 3-6 maanden) voor dat specifieke individu als gevolg van behandeling aangeeft.",
    role: "Rol",
    isLogin: "Is Inloggen?",
    prePilotDashboard: "Dashboard",
    isVisitFormLink: "Is Bezoekformulierlink?",
    qualityOfLifeN: "Kwaliteit van Leven",
    frailtyN: "Kwetsbaarheid",
    category: "Categorie",
    type: "Type",
    baseOnModel: "Gebaseerd op Model",
    item: "Item",
    addSuccessfully: "Succesvol Toegevoegd",
    updateSuccessfully: "Succesvol Bijgewerkt",
    errorMessage: "Ops, er is iets misgegaan, probeer het opnieuw",
    questionSubmittedSuccess: "Vraag Succesvol Verzonden",
    deletedSuccess: "Succesvol Verwijderd",
    resetPasswordSuccessMsg: "Link voor het opnieuw instellen van het wachtwoord is succesvol naar uw e-mail verzonden.",
    successLogin: "U bent succesvol ingelogd",
    otpSuccessMsg: "OTP succesvol naar uw mail verzonden",
    alreadyRegistered: "Al Geregistreerd!",
    otpVerifiedMsg: "OTP Succesvol Geverifieerd!",
    otpSentSuccessMsg: "OTP Succesvol Verzonden!",
    registerSuccessMsg: "Succesvol Geregistreerd!",
    tokenInvalidMsg: "Token ongeldig of verlopen, probeer het opnieuw",
    passwordResetSuccessMsg: "Wachtwoord Succesvol Gereset!",
    yourRequestSubmitSuccessMsg: "Uw aanvraag is succesvol verzonden",
    patientImportSuccessMsg: "Patient Import Successfully",
    deletedSuccessMsg: "Deleted successfully",
    accountAlreadyExistsMsg: "Your account is Successfully registered with us, please login now!",
    fileUploadSuccessMsg: "File Upload Successfully",
    fileDeleteMsg: "File Delete Successfully",
    click: "Klik",
    here: "Hier",
    postQuestionnaireSentence: "on this link and fill the form for further process of",
    postQuestionnaire: "post-questionnaire",
    hospitalizationTooltip2: "Probability of hospitalization to a medical facility in the next 12 months.",
    adlTooltip2: "Probability of having significant worsening in IADL in the next 12 months.",
    patient: "Patient",
    predictionTopic: "Predictions in 12 months",
    riskOfDeclineOfEvent: "Risico op achteruitgang of op incidenten",
    whatIfYouDecideTo: "Wat als je beslist om te:",
    stopAntipsychotics: "Stoppen met Antipschyothica",
    startAnticholinergics: "Starten met Anticholergenica",
    startPhysiotherapy: "Starten met Kinesitherapie",
    predictedChange: "Voorspelde verandering",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "FALLS",
    configuration: "Configuration",
    code: "Code",
    label: "Label",
    index: "Index",
    wp4: "WP4",
    configurations: "Configurations",
    configurationWp4Form: "Configuration Wp4 Form",
    configurationWp4SectionForm: "Configurations Wp4 Section Form",
    wp4Section: "Wp4 Section",
    title: "Title",
    section: "Section",
    isEmailVerified: "Is Email verified?",
    areYourSureWantToLogout: "Are you sure you want to Logout?",
    postQuestion: "for decision questionnaire.",
    toProvideFeedback: "to provide feedback.",
    logout: "Logout",
    clone: "Clone",
    cloneSuccessMsg: "Clone patient data successfully",
    codeSystem: "Code System",
    importExternalPatient: "Import External Patient",
    addPatient: "Add Patient",
    patientList: "Patient List",
    patientForm: "Patient Form",
    save: "Save",
    link1: "Link1",
    link2: "Link2",
    patientFeedbackLink: "Patient Feedback Link",
    forDecisionQuestionnaire: "for Decision questionnaire",
    link: "Link",
    showRaiData: "Show RAI Data",
    close: "Sluiten",
    startPhysiotherapyContent: "Tijdsbestek: tot 6 maanden;  Definitie van kinesitherapie: minimum 4 sessies per week (ALLF); elke sessie duurt minimum 15 minuten, behalve dan voor 'cognitie' (CPS), waar 5 dagen wordt gehanteerd.",
    startPhysiotherapyContent1: "The individual treatment effect (ITE) is the difference between the predicted effect under treatment and the predicted effect if the patient does not receive treatment. A predicted effect lower than 0 indicates less decline, no decline, or greater improvement with therapy compared to no therapy. A predicted effect higher than 0 indicates a greater decline with therapy compared to no therapy.",
    startPhysiotherapyContent2: "The individual treatment effect (ITE) is the difference between the predicted effect under treatment and the predicted effect if the patient does not receive treatment. A predicted effect higher than 0 indicates less decline, no decline, or greater improvement with therapy compared to no therapy. A predicted effect lower than 0 indicates a greater decline with therapy compared to no therapy.",
    startPhysiotherapyContentAdl: "Het individuele behandelingsresultaat (ITE) is het verschil tussen het voorspelde effect bij behandeling en het voorspelde effect als de patiënt geen behandeling krijgt. Een voorspeld effect lager dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering met therapie in vergelijking met geen therapie. Een voorspeld effect hoger dan 0 duidt op een grotere achteruitgang met therapie in vergelijking met geen therapie; Tijdperiode: tot 6 maanden; Resultaat: Activiteiten van het Dagelijks Leven Lange Vorm, van 0 tot 28 punten.",
    stopAntipsychoticsContent1: "The individual treatment effect (ITE) is the difference between the predicted effect after discontinuing antipsychotic medication and the predicted effect of continuing the medication. A predicted effect of less than 0 indicates less decline, no decline, or greater improvement of CHESS with antipsychotic medication discontinuation compared with continued medication. A predicted effect greater than 0 indicates greater decline of CHESS when discontinuing antipsychotic medication compared with continuing medication. Timeframe: up to 12 months",
    stopAntipsychoticsContentAdl: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na stopzetting van antipsychotica en het voorspelde effect bij de voortzetting van de medicatie. Een voorspeld effect kleiner dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering van cognitie (CPS) met stopzetting van de antipsychotica in vergelijking met het verderzetten van de medicatie. Een voorspeld effect groter dan 0 duidt op grotere achteruitgang van cognitie (CPS) met stopzetting van de antipsychotica in vergelijking met het verderzetten van de medicatie. Tijdsbestek: tot 12 maanden.",
    stopAntipsychoticsContentCps: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na stopzetting van antipsychotica en het voorspelde effect bij de voortzetting van de medicatie. Een voorspeld effect kleiner dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering betreffende de instabiliteit in gezondheid (CHESS), met stopzetting van de antipsychotica in vergelijking met het verderzetten van de medicatie. Een voorspeld effect groter dan 0 duidt op een grotere achteruitgang betreffende de instabiliteit in gezondheid (CHESS) met stopzetting van de antipsychotica in vergelijking met het verderzetten van de medicatie.  Tijdsbestek: tot 12 maanden.",
    stopAntipsychoticsContentChess: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na stopzetting van antipsychotica en het voorspelde effect bij de voortzetting van de medicatie. Een voorspeld effect kleiner dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering van ADL, met stopzetting van de antipsychotica in vergelijking met het verderzetten van de medicatie. Een voorspeld effect groter dan 0 duidt op grotere achteruitgang van ADL met stopzetting van de antipsychotica in vergelijking met het verderzetten van de medicatie. Tijdsbestek: tot 12 maanden.",
    stopAntipsychoticsContentHospitalization: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na stopzetting van antipsychotica en het voorspelde effect bij de voortzetting van de medicatie. Een voorspeld effect kleiner dan 0 duidt op een lagere kans op hospitalisatie indien de antipsychotica worden stopgezet, dan als ze zouden worden voortgezet. Een voorspeld effect groter dan 0 betekent een hogere kans op  hospitalisatie indien de antipsychotica worden stopgezet, dan als ze zouden worden voortgezet. Tijdsbestek: tot 12 maanden.",
    startAnticholinergicsAdl: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na het starten met anticholinergica en het voorspelde effect als de patiënt geen medicatie krijgt. Een voorspeld effect kleiner dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering van ADL na het starten met medicatie in vergelijking met geen medicatie. Een voorspeld effect groter dan 0 duidt op grotere achteruitgang van ADL na het starten met medicatie in vergelijking met geen medicatie. Tijdsbestek: tot 12 maanden.",
    startAnticholinergicsCps: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na het starten met anticholinergica en het voorspelde effect als de patiënt geen medicatie krijgt. Een voorspeld effect kleiner dan 0 duidt op minder achteruitgang, geen achteruitgang of grotere verbetering van cognitie (CPS) na het starten met medicatie in vergelijking met geen medicatie. Een voorspeld effect hoger dan 0 duidt op een grotere achteruitgang in cognitie (CPS) met medicatie in vergelijking met geen medicatie. Tijdsbestek: tot 12 maanden.",
    startAnticholinergicsChess: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect na het starten met anticholinergica en het voorspelde effect als de patiënt geen medicatie krijgt. Een voorspeld effect kleiner dan 0 duidt op een lager valriscio na het starten met medicatie in vergelijking met geen medicatie. Een voorspeld effect groter dan 0 duidt op een groter valriscio na het starten met medicatie in vergelijking met geen medicatie. Tijdsbestek: een terugblikperiode van 90 dagen na 12 maanden.",
    startAnticholinergicsHospitalization: "The individual treatment effect (ITE) is the difference between the predicted effect after starting anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect of less than 0 indicates lower probability of hospitalization after starting medication compared to no medication. A predicted effect higher than 0 indicates a higher probability of hospitalization with medication compared to no medication. Timeframe: up to 12 months",
    startAnticholinergicsFalls: "The individual treatment effect (ITE) is the difference between the predicted effect after starting anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates lower probability of falls after starting medication compared to no medication. A predicted effect higher than 0 indicates higher probability of falls with medication compared to no medication. Timeframe: a lookback period of 90 days after 12 months",
    questionnairePopupHeading: "Algemene disclaimer voor alle voorspellingen",
    questionnairePopupHeadingList1: "Wees voorzichtig bij het nemen van beslissingen en weeg uw klinische expertise mee in uw uiteindelijke beslissing, aangezien de nauwkeurigheid van modellen kan variëren tussen zorgontvangers.",
    questionnairePopupHeadingList2: "Deze voorspellingen zijn ontwikkeld en gevalideerd door het I-CARE4OLD-consortium.",
    questionnairePopupHeadingList3: "De voorspellingen zijn gebaseerd op geavanceerde analyses, waaronder machine learning. We hebben de beste wetenschappelijke methoden toegepast op hoogwaardige routine zorggegevens uit meerdere landen.",
    questionnairePopupHeadingList4: "Validaties zijn uitgevoerd op zeer grote steekproeven van hoogwaardige longitudinale gegevens van echte personen in de thuiszorg of verpleeghuizen uit België, Canada, Finland, Hongkong, Italië, Nieuw-Zeeland, Nederland en de Verenigde Staten.",
    questionnairePopupHeadingList5: "Meer informatie:  www.icare4old.eu",
    description: "Beschrijving",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Wp6 Voorspelling",
    configurationWp5Form: "Configuratie Wp5 Formulier",
    configurationWp6Form: "Configuratie Wp6 Formulier",
    boxType: "Type Box",
    addNewSection: "Nieuwe Sectie Toevoegen",
    translation: "Vertaling",
    upload: "Uploaden",
    noRecordFound: "Geen Records Gevonden",
    attachments: "Bijlagen",
    questionnairePopupHeadingList6: "en ga verder met de voorafgaande vragenlijst.",
    preQuestionnaire: "pre-vragenlijst",
    homeCare: "Thuiszorg",
    nursingHome: "Verpleegtehuis",
    afterGeneratePredictionPopup: "Ben je bekend met het patiëntenprofiel?",
    thankYou: "Dank je!",
    predictionIn12Months: "Voorspellingen binnen 12 maanden",
    functionWorsening: "Functionele achteruitgang (ADL + IADL)",
    cognitionWorsening: "Cognitieve achteruitgang (CPS)",
    healthInstability: "Instabiliteit gezondheid (CHESS)",
    otherFalls: "Andere (Valrisico)",
    qualityOfLifeWorsening: "Achteruitgang in levenskwaliteit  (HUI3)",
    riskOfDecline: "Risico op gebeurtenis",
    whatIfYouDecide: "Wat als je besluit om:",
    probabilityOfHospitalization: "Waarschijnlijkheid van ziekenhuisopname in de komende 12 maanden.",
    probabilityOfWorseningIADL: "Waarschijnlijkheid van aanzienlijke verslechtering in IADL in de komende 12 maanden.",
    otherFallsInfoBox: "Het individuele behandelings effect (ITE) is het verschil tussen het voorspelde effect na het starten van anticholinergica en het voorspelde effect als de patiënt geen medicatie ontvangt. Een voorspeld effect lager dan 0 duidt op een lagere kans op valincidenten na het starten van medicatie vergeleken met geen medicatie. Een voorspeld effect hoger dan 0 duidt op een hogere kans op valincidenten met medicatie vergeleken met geen medicatie. Tijdframe: een terugkijkperiode van 90 dagen na 12 maanden.",
    lastAllInfoBox: "Het individuele behandeleffect (IBE) bestaat uit het verschil tussen het voorspelde effect bij behandeling, en het voorspelde effect als de patiënt geen behandeling krijgt. Een voorspeld effect kleiner dan 0 duidt op minder achteruitgang, geen achteruitgang, of een grotere verbetering met therapie in vergelijking met zonder therapie. Een voorspeld effect groter dan 0 duidt op  grotere achteruitgang met therapie in vergelijking met geen therapie. Tijdsbestek: tot 6 maanden",
    hospitalizations: "Hospitalisatie",
    forgotYourPassword: "Vergeet je wachtwoord?",
    forgotPasswordInstructions: "Vul je e-mailadres in en we sturen je een e-mail met instructies.",
    forgotPasswordHeading: "Let op: de e-mail is 24 uur geldig.",
    submit: "Indienen",
    resetYourPassword: "Je wachtwoord opnieuw instellen?",
    pleaseEnterNewPassword: "Vul een nieuw wachtwoord in",
    resetPasswordHeading: "Let op: de e-mail is 24 uur geldig.",
    setupYourPasswordForNewAccount: "Stel je wachtwoord in voor een nieuw account.",
    resetPasswordNewHeading: "Vul je wachtwoord in en je account wordt bij ons geregistreerd zodat je kunt inloggen.",
    resetPasswordNewHeading1: "Let op: de e-mail is 24 uur geldig.",
    patientProfilePredictionVerification: "Verificatie van de patiëntprofielvoorspelling",
    position:"Positie",
    after:"Na",
    before:"Voor",
    predictedChangeFromBasePredictionInCaseOf:"Voorspelde verandering ten opzichte van de basisvoorspelling in het geval van"
};

export default {
    locale: "nl-NL",
    messages: {
        title: titles
    },
};
